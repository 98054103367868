import React from 'react';

const Hero = () => {
  return (
    <section 
      className="min-h-screen bg-gradient-to-r from-blue-900 to-blue-500 flex items-center justify-center" 
    >
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        {/* Left side: Introduction */}
        <div className="text-center md:text-left p-8 md:w-1/2">
          <h2 className="text-6xl font-bold text-white">Dr. Darshan Gandhi,</h2>
          <h3 className="text-4xl font-bold text-white">MS Surgeon</h3>
          <p className="text-xl text-gray-200 mt-4">
            Expert in Cardiac and Thoracic Surgery. Providing world-class surgical care.
          </p>
          <a href="#about" className="mt-8 inline-block bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg shadow-lg transition duration-300">
            Learn More
          </a>
        </div>
        
        {/* Right side: Image */}
        <div className="hidden md:block md:w-1/2">
          <img
            src="https://res.cloudinary.com/dh9gn9s2s/image/upload/v1728844559/b8nmit05wmyygyq5thaz.png"
            alt="Doctor Image"
            className=""
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
