import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white p-6 text-center">
      <p className="mb-4">&copy; {new Date().getFullYear()} Dr. Darshan Gandhi. All rights reserved.</p>
     
    </footer>
  );
};

export default Footer;
