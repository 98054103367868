import React from 'react';

const Services = () => {
  return (
    <section id="services" className="min-h-screen py-16 px-8 bg-gray-100 flex items-center justify-center">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-12">My Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="p-6 bg-white shadow-lg rounded-lg hover:shadow-xl transition duration-300">
            <h3 className="text-2xl font-bold mb-4">Cardiac Surgery</h3>
            <p className="text-gray-600">Advanced heart surgeries with 10+ years of experience.</p>
          </div>
          <div className="p-6 bg-white shadow-lg rounded-lg hover:shadow-xl transition duration-300">
            <h3 className="text-2xl font-bold mb-4">Thoracic Surgery</h3>
            <p className="text-gray-600">Expert thoracic procedures ensuring safety and precision.</p>
          </div>
          <div className="p-6 bg-white shadow-lg rounded-lg hover:shadow-xl transition duration-300">
            <h3 className="text-2xl font-bold mb-4">Minimally Invasive Surgery</h3>
            <p className="text-gray-600">Reduced recovery times with minimal scarring techniques.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
