import React from 'react';

const Experience = () => {
  return (
    <section id="experience" className="min-h-screen py-16 px-8 bg-white flex items-center justify-center">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-12">Experience</h2>
        <div className="relative border-l border-gray-200">
          <div className="mb-10 ml-8">
            <div className="absolute w-3 h-3 bg-blue-500 rounded-full -left-1.5 border border-white"></div>
            <h3 className="text-2xl font-bold">Head of Surgery, ABC Hospital</h3>
            <time className="text-sm text-gray-400">2015 - 2020</time>
            <p className="mt-2 text-gray-600">Led the surgical team for 5 years with excellent patient outcomes.</p>
          </div>
          <div className="mb-10 ml-8">
            <div className="absolute w-3 h-3 bg-blue-500 rounded-full -left-1.5 border border-white"></div>
            <h3 className="text-2xl font-bold">Senior Surgeon, XYZ Medical Center</h3>
            <time className="text-sm text-gray-400">2010 - 2015</time>
            <p className="mt-2 text-gray-600">Performed complex heart surgeries and mentored junior surgeons.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
