import React from 'react';

const Contact = () => {
  return (
    <section id="contact" className="min-h-screen py-16 px-8 bg-gray-100 flex items-center justify-center">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-12">Contact Me</h2>
        <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition duration-300">
          <p className="text-center text-lg mb-6">Feel free to reach out through phone, email, or connect on social media.</p>
          
          {/* Call and Email Buttons */}
          <div className="flex flex-col space-y-4 mb-6">
            {/* Call Me Button */}
            <a 
              href="tel:9404669843" 
              className="w-1/2 mx-auto bg-beige-900 hover:bg-beige-300 text-gray-700 py-2 rounded-lg shadow-md transition duration-300 text-center text-sm"
            >
              Call Me
            </a>

            {/* Mail Me Button */}
            <a 
              href="mailto:dr.john.doe@example.com" 
              className="w-1/2 mx-auto bg-beige-200 hover:bg-beige-300 text-gray-700 py-2 rounded-lg shadow-md transition duration-300 text-center text-sm"
            >
              Mail Me
            </a>
          </div>

          {/* Social Media Connect */}
          <div className="text-center">
            <p className="text-lg font-bold mb-4">Connect with me</p>
            <div className="flex justify-center space-x-6">
              <a href="https://twitter.com" className="text-blue-500 hover:text-blue-600">
                <i className="fab fa-twitter text-3xl"></i>
              </a>
              <a href="https://linkedin.com" className="text-blue-700 hover:text-blue-800">
                <i className="fab fa-linkedin text-3xl"></i>
              </a>
              <a href="https://www.instagram.com/darshan_gandhi_/" className="text-pink-500 hover:text-pink-600">
                <i className="fab fa-instagram text-3xl"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
