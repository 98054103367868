import React from 'react';

const About = () => {
  return (
    <section id="about" className="py-16 px-8 bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-8">About Me</h2>
        <p className="text-center text-lg max-w-2xl mx-auto">
          I am Dr. Darshan Gandhi, a board-certified surgeon with over 1+ years of experience in cardiac and thoracic surgery.
          My passion for precision and care has earned me recognition across multiple medical institutions.
        </p>
      </div>
    </section>
  );
};

export default About;
